
import { useContext } from "react";
import { useHistory } from "react-router-dom";
import { Layout, Menu } from 'antd';
import { Context } from "./Context"; 
import CarrierLogo from './assets/CarrierLogo';

const { Content, Sider } = Layout;

const items = [
    {
        key: "home",
        path: "/",
        label: "Home"
    },
    {
        key: "manage-feature-flags",
        path: "/manage-feature-flags",
        label: "Manage Feature Flags"
    },
    {
      key: "provisioning-status",
      path: "/provision-status",
      label: "Provisioning Status"
  }
]

const Nav = ({ children }) => {

  const history = useHistory();
  
  const context = useContext(Context); 

    const handleMenuClick = ({ key }) => {
      const item = items.filter(item => item.key === key)[0]; 
        context.setNavActiveKey(item.key)
        history.push(item.path);
    }

  return (
    <Layout className="min-vh-100">
      <Sider className="sider">
        <div className="d-flex justify-content-center m-1">
          <div className="w-75">
            <CarrierLogo />
          </div>
        </div>
        <Menu theme="dark" defaultSelectedKeys={['home']} selectedKeys={[context.navActiveKey]} mode="inline" items={items} onClick={handleMenuClick} />
      </Sider>
      <Layout>
        <Content>
            {children}
        </Content>
      </Layout>
    </Layout>
  );
};
export default Nav;