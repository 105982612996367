import { useState, useContext } from "react"; 
import { useHistory } from "react-router-dom"; 
import { useOktaAuth } from '@okta/okta-react';
import { Context } from "../Context"; 
import { Button, Input, Radio, Table, notification } from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import { getFeatureFlags, deleteFeatureFlag } from "../grapqhl-queries"; 

const ModifyFeatureFlag = () => {

    const columns = [
        {
          title: 'Feature ID',
          dataIndex: 'featureId',
          key: 'featureId',
        },
        {
          title: 'Enabled',
          dataIndex: 'enabled',
            key: 'enabled',
            render: (text, record, index) => {
                return <span>{record.enabled ? 'true' : 'false'}</span>
            }
        },
        {
          title: 'Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
        },
        {
            title: 'Edit',
            key: 'edit',
            render: (text, record, index) => { 
                return (
                    <>
                        <Button className="mb-3" type="primary" ghost onClick={() => handleEditFeatureFlag(record)}>Edit</Button>
                    </>
                )
            }
        },
        {
            title: 'Delete',
            key: 'delete',
            render: (text, record, index) => { 
                return (
                    <>
                        <Button className="mb-3" type="primary" danger ghost onClick={() => handleDeleteFeatureFlag(record)}>Delete</Button>
                    </>
                )
            }
        }
    ];

    const history = useHistory();
    
    const context = useContext(Context); 

    const { authState, oktaAuth } = useOktaAuth();

    if( !authState.isAuthenticated || !authState.accessToken.accessToken ) { 
        oktaAuth.signInWithRedirect({ originalUri: "/manage-feature-flags" });
      }

    const [api, contextHolder] = notification.useNotification();

    const [featureFlagType, setFeatureFlagType] = useState('DEFAULT_FEATURE'); 
    const [brand, setBrand] = useState(''); 
    const [username, setUsername] = useState(''); 
    const [os, setOs] = useState(''); 
    const [features, setFeatures] = useState([]);
    const [featureId, setFeatureId] = useState('');

    const [getFeatureFlagsFunction] = useLazyQuery(getFeatureFlags, {
        context: {
          headers: {
            "Authorization": `Bearer ${authState.accessToken.accessToken}`
          }
        }
    });
    
    const [deleteFeatureFlagFunction, { }] = useMutation(deleteFeatureFlag, {
        context: {
          headers: {
            "Authorization": `Bearer ${authState.accessToken.accessToken}`
          }
        }
      });

    const handleFeatureFlagTypeChange = (e) => {
        setFeatureFlagType(e.target.value); 
    }

    const handleSearch = async () => { 

        console.log('Inside handleSearch'); 

        context.setLoading(true); 

        getFeatureFlagsFunction({
            variables: {
                featureFlagType,
                brand,
                username,
                os,
                featureId
            },
            onCompleted: (data) => {

                if (data && data.getFeatureFlags && data.getFeatureFlags.features) {
                    let features = data.getFeatureFlags.features.map(f => ({
                        ...f,
                        featureFlagType,
                        brand,
                        username,
                        os
                    }))
                    setFeatures(features);
                    
                } else { 
                    setFeatures([]);
                }
                context.setLoading(false); 

            },
            onError: () => {
                context.setLoading(false);
                api.error({
                    message: 'Error!',
                    description: 'Error while searching feature flags.',
                });
            }
        });
 
    }

    const handleEditFeatureFlag = (record) => { 
        history.push({
            pathname: '/manage-feature-flags',
            state: {
                activeTab: 'addOrEditFeatureFlag',
                editMode: 'true',
                featureFlagType: record.featureFlagType,
                brand: record.brand,
                username: record.username,
                os: record.os,
                featureId: record.featureId,
                enabled: record.enabled,
                featureName: record.name,
                description: record.description
            }
        }); 
    }

    const handleDeleteFeatureFlag = (record) => { 

        const { featureFlagType, brand, username, os, featureId } = record; 

        context.setLoading(true); 

        deleteFeatureFlagFunction({
            variables: {
                featureFlagType,
                brand,
                username,
                os,
                featureId
            },
            onCompleted: () => { 
                context.setLoading(false); 
                api.success({
                    message: 'Success!',
                    description: 'Feature Flag deleted successfully.',
                });
                setFeatures(prevState => prevState.filter(f => f.featureId !== featureId));
            },
            onError: () => { 
                context.setLoading(false); 
                api.error({
                    message: 'Error!',
                    description: 'Error while deleting feature flag.',
                });
            }
        });
    }

    return (
        <>
            {contextHolder}
            <div className="mb-3">
                <div className="d-flex align-items-center">
                    <div className="w-25">Feature Type: </div>
                    <div>
                    <Radio.Group onChange={handleFeatureFlagTypeChange} value={featureFlagType}>
                        <Radio value={'DEFAULT_FEATURE'}>Default Feature</Radio>
                        <Radio value={'USER_FEATURE'}>User Feature</Radio>
                        <Radio value={'OS_FEATURE'}>OS Feature</Radio>
                        <Radio value={'MIGRATION'}>Migration</Radio>
                    </Radio.Group>
                    </div>
                </div>
            </div>
            {featureFlagType === 'MIGRATION' ?
                <div className="mb-3">
                    <div className="d-flex align-items-center">
                        <div className="w-25">Feature ID: </div>
                        <div className="w-25">
                            <Input placeholder="Feature ID" onChange={(e) => setFeatureId(e.target.value)} value={featureId}></Input>
                        </div>
                    </div>
                </div> :
                <div className="mb-3">
                    <div className="d-flex align-items-center">
                        <div className="w-25">Brand: </div>
                        <div className="w-25">
                            <Input placeholder="Brand" onChange={(e) => setBrand(e.target.value)} value={brand}></Input>
                        </div>
                    </div>
                </div> 
            }
            {(featureFlagType === 'USER_FEATURE') ? 
                    <div className="mb-3">
                        <div className="d-flex align-items-center">
                            <div className="w-25">Username: </div>
                            <div className="w-25">
                                <Input placeholder="Username" onChange={(e) => setUsername(e.target.value)} value={username}></Input>
                            </div>
                        </div>
                    </div>
                : <></>}
            {(featureFlagType === 'OS_FEATURE') ? 
                    <div className="mb-3">
                        <div className="d-flex align-items-center">
                            <div className="w-25">OS: </div>
                            <div className="w-25">
                            <Input placeholder="OS" onChange={(e) => setOs(e.target.value)} value={os}></Input>
                            </div>
                        </div>
                    </div>
            : <></>}
            <Button className="mb-3" type="primary" onClick={handleSearch}>Search</Button>
            <Table dataSource={features} columns={columns} />
        </>
    )
  };
  
  export default ModifyFeatureFlag;