import './App.css';
import { ApolloProvider, ApolloClient, InMemoryCache } from "@apollo/client";
import { BrowserRouter, Switch, Route, useHistory } from "react-router-dom";
import { SecureRoute, Security, LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { Provider } from "./Context";
import Nav from "./Nav.jsx";
import Header from "./components/Header.jsx";
import Home from "./components/Home";
import ManageFeatureFlags from "./components/ManageFeatureFlags";
import ProvisionStatus from "./components/ProvisionStatus.jsx";


const client = new ApolloClient({
  uri: process.env.REACT_APP_DATA_SERVICE_ENDPOINT,
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

const oktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_OKTA_ISSUER,
  clientId: process.env.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: process.env.REACT_APP_OKTA_REDIRECT_URI,
});

function App() {

  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <div>
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
        <ApolloProvider client={client}>
          <Provider>
            <Nav>
              <Switch>
                <Route exact={true} path="/login/callback" component={LoginCallback}></Route>
                <Header>
                  <Route exact={true} path="/"><Home /></Route>
                  <SecureRoute exact={true} path="/manage-feature-flags"><ManageFeatureFlags /></SecureRoute>
                  <SecureRoute exact={true} path="/provision-status"><ProvisionStatus /></SecureRoute>
                </Header>
              </Switch>
            </Nav>
          </Provider>
        </ApolloProvider>
      </Security>
    </div >
  );
}

function RouterApp() {
  return (
    <BrowserRouter>
      <App />
    </BrowserRouter>
  )
}

export default RouterApp;
