import { useState, useContext, useEffect } from "react"; 
import { useHistory, useLocation } from "react-router-dom"; 
import { useOktaAuth } from '@okta/okta-react';
import { Context } from "../Context"; 
import { Button, Input, Radio, notification } from "antd";
import { useMutation } from "@apollo/client";
import { addFeatureFlag, updateFeatureFlag } from "../grapqhl-queries"; 

const AddOrEditFeatureFlag = ({ }) => {

    const history = useHistory();

    const location = useLocation();

    const { authState, oktaAuth } = useOktaAuth();

    if( !authState.isAuthenticated || !authState.accessToken.accessToken ) { 
      oktaAuth.signInWithRedirect({ originalUri: "/manage-feature-flags" });
    }

    const context = useContext(Context); 

    const [api, contextHolder] = notification.useNotification();

    const [editMode, setEditMode] = useState(false); 
    const [featureFlagType, setFeatureFlagType] = useState('DEFAULT_FEATURE'); 
    const [brand, setBrand] = useState(''); 
    const [username, setUsername] = useState(''); 
    const [os, setOs] = useState(''); 
    const [featureId, setFeatureId] = useState(''); 
    const [enabled, setEnabled] = useState(true); 
    const [featureName, setFeatureName] = useState(''); 
    const [description, setDescription] = useState(''); 

    useEffect(() => { 
        console.log(location); 
        if (location.state) { 
            let state = location.state;
            if ('editMode' in state) { setEditMode(state.editMode); delete state.editMode; } 
            if ('featureFlagType' in state) { setFeatureFlagType(state.featureFlagType); delete state.featureFlagType; }
            if ('brand' in state) { setBrand(state.brand); delete state.brand; }
            if ('username' in state) { setUsername(state.username); delete state.username }
            if ('os' in state) { setOs(state.os); delete state.os; }
            if ('featureId' in state) { setFeatureId(state.featureId); delete state.featureId; }
            if ('enabled' in state) { setEnabled(state.enabled); delete state.enabled; }
            if ('featureName' in state) { setFeatureName(state.featureName); delete state.featureName; }
            if ('description' in state) { setDescription(state.description); delete state.description; }
        }
    }, [location]);

    const [addFeatureFlagFunction, { }] = useMutation(addFeatureFlag, {
        context: {
          headers: {
            "Authorization": `Bearer ${authState.accessToken.accessToken}`
          }
        }
    });
    
    const [updateFeatureFlagFunction, { }] = useMutation(updateFeatureFlag, {
        context: {
          headers: {
            "Authorization": `Bearer ${authState.accessToken.accessToken}`
          }
        }
      });

    const handleFeatureFlagTypeChange = (e) => {
        setFeatureFlagType(e.target.value); 
    }

    const handleAddFeatureSubmit = () => {

        context.setLoading(true); 
        if (!editMode) {
            addFeatureFlagFunction({
                variables: {
                    featureFlagType,
                    brand,
                    username,
                    os,
                    featureId,
                    enabled,
                    featureName,
                    description
                },
                onCompleted: () => {
                    context.setLoading(false);
                    api.success({
                        message: 'Success!',
                        description: 'Feature Flag added successfully.',
                    });

                    history.push({
                        pathname: '/manage-feature-flags',
                        state: {
                            activeTab: 'modifyFeatureFlag'
                        }
                    })
    
                },
                onError: () => {
                    context.setLoading(false);
                    api.error({
                        message: 'Error!',
                        description: 'Error while adding feature flag.',
                    });
                }
            });
        } else { 
            updateFeatureFlagFunction({
                variables: {
                    featureFlagType,
                    brand,
                    username,
                    os,
                    featureId,
                    enabled,
                    featureName,
                    description
                },
                onCompleted: () => {
                    context.setLoading(false);
                    api.success({
                        message: 'Success!',
                        description: 'Feature Flag updated successfully.',
                    });

                    history.push({
                        pathname: '/manage-feature-flags',
                        state: {
                            activeTab: 'modifyFeatureFlag'
                        }
                    }); 
    
                },
                onError: () => {
                    context.setLoading(false);
                    api.error({
                        message: 'Error!',
                        description: 'Error while updating feature flag.',
                    });
                }
            });
        }

    }

    return (
        <>
            { contextHolder }
            <div className="mb-3">
                <div className="d-flex align-items-center">
                    <div className="w-25">Feature Type: </div>
                    <div>
                        <Radio.Group onChange={handleFeatureFlagTypeChange} value={featureFlagType} disabled={editMode} >
                            <Radio value={'DEFAULT_FEATURE'}>Default Feature</Radio>
                            <Radio value={'USER_FEATURE'}>User Feature</Radio>
                            <Radio value={'OS_FEATURE'}>OS Feature</Radio>
                            <Radio value={'MIGRATION'}>Migration</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
            {featureFlagType !== 'MIGRATION' && <div className="mb-3">
                <div className="d-flex align-items-center">
                    <div className="w-25">Brand: </div>
                    <div className="w-25">
                        <Input placeholder="Brand" onChange={(e) => setBrand(e.target.value)} value={brand} disabled={ editMode }></Input>
                    </div>
                </div>
            </div>}
            {(featureFlagType === 'USER_FEATURE') ? 
                    <div className="mb-3">
                        <div className="d-flex align-items-center">
                            <div className="w-25">Username: </div>
                            <div className="w-25">
                                <Input placeholder="Username" onChange={(e) => setUsername(e.target.value)} value={username} disabled={ editMode }></Input>
                            </div>
                        </div>
                    </div>
                : <></>}
            {(featureFlagType === 'OS_FEATURE') ? 
                    <div className="mb-3">
                        <div className="d-flex align-items-center">
                            <div className="w-25">OS: </div>
                            <div className="w-25">
                            <Input placeholder="OS" onChange={(e) => setOs(e.target.value)} value={os} disabled={ editMode }></Input>
                            </div>
                        </div>
                    </div>
            : <></>}
            <div className="mb-3">
                <div className="d-flex align-items-center">
                    <div className="w-25">Feature ID: </div>
                    <div className="w-25">
                        <Input placeholder="Feature ID" onChange={(e) => setFeatureId(e.target.value)} value={featureId} disabled={ editMode }></Input>
                    </div>
                </div>
            </div>
            <div className="mb-3">
                <div className="d-flex align-items-center">
                    <div className="w-25">Enabled: </div>
                    <div className="w-25">
                        <Radio.Group onChange={(e) => setEnabled(e.target.value)} value={enabled}>
                            <Radio value={true}>True</Radio>
                            <Radio value={false}>False</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
            {featureFlagType !== 'MIGRATION' &&
                <div className="mb-3">
                    <div className="d-flex align-items-center">
                        <div className="w-25">Feature Name: </div>
                        <div className="w-25">
                            <Input placeholder="Feature Name" onChange={(e) => setFeatureName(e.target.value)} value={featureName}></Input>
                        </div>
                    </div>
                </div>
            }
            {featureFlagType !== 'MIGRATION' && 
                <div className="mb-3">
                    <div className="d-flex align-items-center">
                        <div className="w-25">Description: </div>
                        <div className="w-25">
                        <Input className="mb-3" placeholder="Description" onChange={(e) => setDescription(e.target.value)} value={description}></Input>
                        </div>
                    </div>
                </div>
            }
            <Button type="primary" onClick={handleAddFeatureSubmit}>{ editMode === false ? 'Add' : 'Update' } Feature</Button>
        </>
    )
  };
  
  export default AddOrEditFeatureFlag;