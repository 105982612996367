import { useOktaAuth } from "@okta/okta-react"; 
import { Button } from 'antd';

const Header = ({ children }) => {

  const { authState, oktaAuth } = useOktaAuth();

    const handleLogin = async () => { 
      oktaAuth.signInWithRedirect({ originalUri: "/" });
    }
  
    const handleLogout = async () => { 
      await oktaAuth.signOut();
    }

  return (
  
      <>
      <div className="p-3 w-100 d-flex justify-content-end">
        {authState && authState.isAuthenticated ?
          <Button type="primary" danger onClick={handleLogout}>Logout</Button> :
          <Button type="primary" onClick={handleLogin}>Login</Button>
        }
        </div>
        { children }
      </>
    )
  };
  
  export default Header;