import { useState, useContext, useEffect } from "react"; 
import { useLocation } from "react-router-dom"; 
import { Context } from "../Context"
import { Spin, Tabs } from "antd";
import AddOrEditFeatureFlag from "./AddOrEditFeatureFlag"; 
import ModifyFeatureFlag from "./ModifyFeatureFlag"; 

const tabs = [
  {
    key: 'addOrEditFeatureFlag',
    label: `Add/Edit Feature Flag`,
  },
  {
    key: 'modifyFeatureFlag',
    label: `Modify Feature Flag`,
  },
]

const ManageFeatureFlags = () => {

  const context = useContext(Context); 

  const location = useLocation();

  const [activeTab, setActiveTab] = useState('addOrEditFeatureFlag'); 

  useEffect(() => { 
    context.setNavActiveKey('manage-feature-flags');
    if (location.state) { 
      let state = location.state;
      if ("activeTab" in state) { 
        setActiveTab(state.activeTab); 
        delete state.activeTab; 
      }
    }
  }, [location]);

    const handleTabChange = (key) => {
      setActiveTab(key); 
    }
  
  return (
    <>
    <Spin spinning={context.loading} delay={500}>
            <div className="p-5">
            <div className="mb-5">
              <h3>Manage Feature Flags</h3>
            </div>
        <Tabs activeKey={ activeTab } items={tabs} onChange={handleTabChange} />
        {(activeTab === 'addOrEditFeatureFlag') ? <AddOrEditFeatureFlag />: <></>}
        {(activeTab === 'modifyFeatureFlag') ? <ModifyFeatureFlag />: <></>}
          </div>
      </Spin>
    </>
    )
  };
  
  export default ManageFeatureFlags;