import React, { useState } from 'react'

export const Context = React.createContext();

export const Provider = ({ children }) => {

    const [loading, setLoading] = useState(false); 
    const [navActiveKey, setNavActiveKey] = useState('home'); 

    return (
        <Context.Provider value={{
            loading,
            setLoading,
            navActiveKey,
            setNavActiveKey
        }}>
            {children}
        </Context.Provider>
    )
}

