import { useContext, useEffect } from "react";
import { Context } from "../Context"; 

const Home = () => {

  const context = useContext(Context);

  useEffect(() => { 
    context.setNavActiveKey('home');
  }); 
    return (
      <>
        <div className="p-5">
          <h6 className="mb-3">Admin portal modules: </h6>
          <ul>
            <li>
              Feature Flag Management
            </li>
            <li>
              Provisioning status
            </li>
          </ul>
        </div>
      </>
    )
  };
  
  export default Home;