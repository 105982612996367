import axios from "axios";

export const fetchProvisioningStatus = async (serialList, accessToken) => {
    const trimSerialList = serialList.replace(/\s/g, '');
    const data = { serialList: trimSerialList }
    console.log("payload", data);
    try {
      const response = await axios.post(`${process.env.REACT_APP_INFINITY_ADMIN_BASE_URL}/systems/provisioning-status`, data, {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': accessToken
        }
      });
      return response.data;
    } catch (e) {
      console.log("errored out!!!")
    }
 }
 