import { useState, useContext } from "react";
import { useOktaAuth } from '@okta/okta-react';
import { Context } from "../Context";
import { Button, Input, Table, notification, Form, Spin, Alert } from "antd";
import { fetchProvisioningStatus } from '../adminApi';

const ProvisionStatus = () => {

    const columns = [
        {
            title: 'Serial Number',
            dataIndex: 'serial',
            key: 'serial',
        },

        {
            title: 'Provisioned',
            dataIndex: 'provisioned',
            key: 'provisioned',
        },
        {
            title: 'WhiteListed',
            dataIndex: 'whiteListed',
            key: 'whiteListed',
        },
    ];
    const context = useContext(Context);

    const { authState, oktaAuth } = useOktaAuth();

    if (!authState.isAuthenticated || !authState.accessToken.accessToken) {
        oktaAuth.signInWithRedirect({ originalUri: "/provision-status" });
    }

    const [api, contextHolder] = notification.useNotification();
    const [serialData, setSerialData] = useState([]);

    const handleSearch = async (values) => {
        console.log('Inside handleSearch', values);
        context.setLoading(true);
        setSerialData([]);
        try {
            // api call
            // values.serials, authState.accessToken.accessToken  
            const provisionData = await fetchProvisioningStatus(values.serials, `Bearer ${authState.accessToken.accessToken}`);
            if (provisionData && provisionData.length) {
                setSerialData(provisionData);
            } 
            context.setLoading(false);
        }
        catch (e) {
            context.setLoading(false);
            api.error({
                message: 'Error!',
                description: 'Error while fetching provisioning status',
            });
        }
    }
    return (
        <>
            {contextHolder}
            <Form
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    serials: ''
                }}
                onFinish={handleSearch}
                validateTrigger='onSubmit'
            >
                <Form.Item
                    label="Serial Numbers"
                    name="serials"
                    rules={[
                        {
                            required: true,
                            message: 'Serial Number is required',
                        },
                        {
                            pattern: /^[^,]+(?:,[^,]+){0,9}$/,
                            message: 'Incorrect input or Serial Numbers max limit exceeded'
                        },
                    ]}
                >
                    <Input />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button type="primary" htmlType="submit">
                        Search
                    </Button>
                </Form.Item>
            </Form>
            {context.loading ? 
            <Spin size="large" className='loader'/>
            : <></>}
            {serialData.length ? <Table dataSource={serialData} columns={columns} /> : <></>}

        </>
    )
};

export default ProvisionStatus;